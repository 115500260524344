<template>
  <div class="content-section body-wpr">
    <div class="body-wp">
      <p style="padding: 26px 8px">알림 설정</p>
      <select-box
        :opt-nm="categoryList"
        v-model="params.categoryEsntlId"
        style="width: 150px; text-align: right; margin-left: 8px"
        @input="getData"
      />
      <table class="search-table" style="margin-top: 16px">
        <thead>
          <colgroup>
            <col v-for="(col, colIdx) in tblCol" :key="colIdx" :width="col" />
          </colgroup>
        </thead>
        <tr>
          <th v-for="(fld, fldIdx) in tblFld" :key="fldIdx">{{ fld }}</th>
        </tr>
        <tr v-for="(row, rowIdx) in tblRow" :key="rowIdx">
          <td>{{ row.category }}</td>
          <td>{{ row.conditions }}</td>
          <td>{{ row.content }}</td>
          <td>{{ row.url }}</td>
          <td>
            <button-common :size="SIZE_T" :clr="CLR_W" @click="openEditModal(row)">수정</button-common>
          </td>
        </tr>
      </table>
      <div class="div-search-null" v-if="tblRow.length === 0">
        검색 데이터가 없습니다.
      </div>
      <pagination class="mt-16" @click="onChangePage" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "AlarmCompanyList",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** data ***/
  data() {
    return {
      params: {
        page: 1,
        numOfData: 15,
        categoryEsntlId: "",
      },
      totCnt: 0,
      range: {
        start: new Date(),
        end: new Date(),
      },
      popover: {
        visibility: "hidden",
      },
      tblCol: ["10%", "10%", "50%", "32%", "8%"],
      tblFld: ["카테고리", "조건", "내용", "연결페이지", "설정"],
      tblRow: [],
      categoryList: [],
    };
  },

  /*** created hook ***/
  async created() {
    await this.getCategory();
    await this.getData();
  },

  /*** computed ***/
  computed: {
    ...mapGetters([
      "G_PAGE",
      "G_ORDER",
      "G_LSTCNT",
      "G_MODAL_TOGGLE",
    ]),
    cls() {
      let cls = "";
      cls += "input-rel ";
      cls += this.wpCls || "";
      return cls;
    },
  },

  /*** watch ***/
  watch: {
    async G_MODAL_TOGGLE(val) {
      // 닫힘
      if (!val) {
        this.getData();
      }
    },
  },

  /*** methods ***/
  methods: {
    // 데이터 가져오기 공통모듈
    async getData() {
      const rs = await this.$axios({
        url: "/admin/alarm/partner",
        params: this.params,
      });
      if (rs.result === "SUC") {
        this.tblRow = rs.resultList;
        this.totCnt = rs.totcnt;
        await this.$store.dispatch("A_PAGE", rs.page);
        await this.$store.dispatch("A_TOTCNT", rs.totcnt);
      }
    },

    async getCategory() {
      const rs = await this.$axios({
        url: "/admin/alarm/partner/category",
        params: {},
      });
      if (rs.result === "SUC") {
        this.categoryList = rs.resultList;
        this.categoryList = this.categoryList.map((item) => {
          return { title: item.title, value: item.esntlId };
        });
        this.categoryList.unshift({ title: "전체/카테고리", value: "" });
      }
    },

    // 페이지 이동
    async onChangePage(value) {
      this.params = { ...this.params, page: value };
      this.pager = { ...this.pager, page: value };
      await this.getData();
    },

    async openEditModal(row) {
      // 수정 클릭 시
      await this.$store.dispatch("A_IS_MEMBER", false);
      await this.$store.dispatch("A_ALARM_DATA", row);
      await this.$openAlarm({}, this);
    },
  },
};
</script>

<style scoped lang="scss">
.statistic-area {
  display: flex;
  align-items: center;
  width: 100%;
  height: 96px;
  padding-left: 38px;
}
.statistic-area-wrap {
  display: flex;
  align-items: center;
  margin-right: 100px;
  cursor: pointer;
}
.statistic-img {
  width: 36px;
  height: 36px;
}
.statistic-title {
  font-weight: 400;
  font-size: 14px;
  color: #515466;
}
.statistic-count {
  font-weight: 500;
  font-size: 16px;
  color: #2CCDC3;
}
.sort-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.sort-count {
  font-size: 16px;
  font-weight: 500;
  color: #515466;
}
.select-button {
  color: #ffffff;
  background: #696c80;
}
.div-search-null {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--gray20);
}
.status-term {
  font-weight: 500;
  color: #12131a;
  font-size: 14px;
}
.status-used {
  font-weight: 400;
  color: #12131a;
  font-size: 14px;
}
.status-end {
  font-weight: 500;
  color: #f1431d;
  font-size: 14px;
}
.member-link {
  text-decoration: underline;
  cursor: pointer;
}
</style>
